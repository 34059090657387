import { lazy, ReactElement, Suspense } from 'react'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
import { Navigate, Route, Routes } from 'react-router-dom'
const PartnerDashboard = lazy(() => import('../pages/partners/PartnerDashboard'))
const PartnerPricing = lazy(() => import('../pages/partners/PartnerPricing'))
const PartnerQuotes = lazy(() => import('../pages/partners/PartnerQuotes'))
const CreateQuote = lazy(() => import('../pages/partners/CreateQuote'))
const EditQuote = lazy(() => import('../pages/partners/EditQuote'))
const PartnerCustomers = lazy(() => import('../pages/partners/PartnerCustomers'))
const CustomerEvents = lazy(() => import('../pages/partners/CustomerEvents'))
const PartnerInvoices = lazy(() => import('../pages/partners/PartnerInvoices'))
const EditInvoice = lazy(() => import('../pages/partners/EditInvoice'))
const ViewInvoice = lazy(() => import('../pages/partners/ViewInvoice'))
const PartnerAccount = lazy(() => import('../pages/partners/PartnerAccount'))

export const PartnerRoutes = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <Routes>
        <Route path="/*" element={<Navigate replace to="/partners/dashboard" />} />
        <Route path="/dashboard" element={<PartnerDashboard />} />
        <Route path="/pricing" element={<PartnerPricing />} />
        <Route path="/quotes" element={<PartnerQuotes />} />
        <Route path="/quotes/create" element={<CreateQuote />} />
        <Route path="/quotes/:referenceId" element={<EditQuote />} />
        <Route path="/customers" element={<PartnerCustomers />} />
        <Route path="/customers/:accountId/events" element={<CustomerEvents />} />
        <Route path="/invoices" element={<PartnerInvoices />} />
        <Route path="/invoices/create" element={<EditInvoice />} />
        <Route path="/invoices/:id" element={<EditInvoice />} />
        <Route path="/invoices/:id/view" element={<ViewInvoice />} />
        <Route path="/account" element={<PartnerAccount />} />
      </Routes>
    </Suspense>
  )
}

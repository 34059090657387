import { ReactElement, useEffect, useState } from 'react'
import { FieldSet } from '../../models/manager/fieldset'
import { errorHandler, getFileExtension, isKioskMode } from '../../utils/helper'
import { fetchFileAsDataUrl, saveImageWithProgress } from '../../services/blobStorage'
import { Button, Skeleton, Upload, message } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/es/upload'
import { UploadOutlined } from '@ant-design/icons'
import { updateRecordData } from '../../services/records'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfileContext } from '../../contexts/profileContext'
import { AccountPermissions } from '../../constants/common'
import { checkPermission } from '../../utils/permissions'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record: any
  item: FieldSet
  recordSetId: number
  fieldSetId: number
  refresh: () => void
  showChangeButton?: boolean
  accountUid?: string
  kioskKey?: string
}

export const ImageCell = ({
  record,
  item,
  recordSetId,
  fieldSetId,
  refresh,
  showChangeButton = true,
  accountUid,
  kioskKey,
}: Props): ReactElement => {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()

  const [imgUrl, setImgUrl] = useState('')
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    const load = async () => {
      if (record[item.Name] && record[item.Name].length > 0) {
        let fileUrl = ''

        if (isKioskMode(kioskKey)) {
          fileUrl = `record-data/${recordSetId}/${fieldSetId}-${record.__cn_id}.${getFileExtension(record[item.Name])}`
        } else {
          fileUrl = `${accountUid == null ? profile.AccountUid : accountUid}/record-data/${recordSetId}/${fieldSetId}-${
            record.__cn_id
          }.${getFileExtension(record[item.Name])}`
        }
        const token = isKioskMode(kioskKey) ? '' : await getAccessTokenSilently()
        fetchFileAsDataUrl(fileUrl, token, kioskKey)
          .then((data) => {
            setImgUrl(data.data)
          })
          .catch(errorHandler)
      }
    }

    load()
  }, [record, item])

  return (
    <>
      {contextHolder}
      {imgUrl ? (
        <img src={imgUrl} alt={record[item.Name]} width={40} height={40} className="rounded transition ease-in" />
      ) : (
        <Skeleton.Avatar size={40} />
      )}
      <Upload
        accept="image/png, image/jpeg, image/jpg, image/gif, image/webP, image/svg, image/ico"
        customRequest={async (e) => {
          if (e.data) {
            e.data['storageName'] = `${fieldSetId}-${record.__cn_id}.${getFileExtension((e.file as RcFile).name ?? '')}`
            e.data['folder'] = `${profile.AccountUid}/record-data/${recordSetId}`
          }
          const token = isKioskMode(kioskKey) ? '' : await getAccessTokenSilently()
          saveImageWithProgress(e, token, kioskKey)
        }}
        name="file"
        showUploadList={false}
        onChange={async (info: UploadChangeParam) => {
          if (info.file.status === 'done') {
            messageApi.open({
              type: 'success',
              content: `${info.file.name} file uploaded successfully`,
              style: { marginTop: '15vh' },
            })
            await updateRecordData(
              {
                RecordData: [
                  {
                    FieldSetId: fieldSetId,
                    Value: info.file.response.name,
                    RecordId: +record.__cn_id || 0,
                    CreatedBy: `${profile.Email}`,
                    UpdatedBy: `${profile.Email}`,
                  },
                ],
                RecordSetId: recordSetId,
                CreatedBy: `${profile.Email}`,
                UpdatedBy: `${profile.Email}`,
              },
              await getAccessTokenSilently()
            )
            refresh()
          } else if (info.file.status === 'error') {
            messageApi.open({
              type: 'error',
              content: `${info.file.name} file upload failed.`,
              style: { marginTop: '15vh' },
            })
          }
        }}
      >
        {showChangeButton && (
          <Button
            className="flex items-center"
            icon={<UploadOutlined />}
            disabled={!checkPermission(AccountPermissions.CanManageEventData, profile.Permissions)}
          >
            Change{' '}
          </Button>
        )}
      </Upload>
    </>
  )
}

import { ReactElement, useEffect, useRef, useState } from 'react'
import { errorHandler } from '../../utils/helper'
import { fetchFileAsDataUrl } from '../../services/blobStorage'
import { Skeleton } from 'antd'
import { TEMPLATE_THUMBNAIL_FOLDER } from '../../constants/common'
import { useAuth0 } from '@auth0/auth0-react'
import { TemplateType } from '../../models/manager/template'

interface Props {
  templateId: number
  extension: string
  thumbnailLocation: string
  type?: TemplateType
  width?: string
  mode?: string
  containerWidth?: number
  containerHeight?: number
  imageWidth?: number
  imageHeight?: number
  padding?: string
}

export const TemplateThumbnail = ({
  templateId,
  extension,
  thumbnailLocation,
  width,
  mode,
  containerWidth,
  containerHeight,
  imageWidth,
  imageHeight,
  padding,
}: Props): ReactElement => {
  const { getAccessTokenSilently } = useAuth0()
  const [imgUrl, setImgUrl] = useState('')
  const [isOverflowing, setIsOverflowing] = useState(false)
  const imgRef = useRef(null)

  useEffect(() => {
    if (templateId) {
      const source = thumbnailLocation
      const fileUrl = `${source}${TEMPLATE_THUMBNAIL_FOLDER}/${templateId}_tn.${extension}`

      getAccessTokenSilently().then((token) => {
        fetchFileAsDataUrl(fileUrl, token)
          .then((data) => {
            setImgUrl(data.data)
          })
          .catch(errorHandler)
      })
    }
  }, [templateId])

  useEffect(() => {
    if (imgRef.current && imgUrl && containerHeight && padding) {
      const imgElement = imgRef.current as HTMLImageElement
      if (imgElement.complete) {
        checkOverflow(imgElement)
      }
    }
  }, [imgUrl, containerHeight, padding])

  const checkOverflow = (imgElement: HTMLImageElement) => {
    if (imgElement && containerHeight && padding) {
      const imgHeight = imgElement.naturalHeight
      const availableHeight = containerHeight - parseInt(padding) * 2
      setIsOverflowing(imgHeight > availableHeight)
    }
  }

  const listSpanWidth = width ? `min-w-[${width}px] max-w-[${width}px]` : 'min-w-[50px] max-w-[50px]'
  const listImgWidth = width
    ? `mt-[-25px] mb-[-25px] min-h-[${width}px] max-h-[${width}px] min-w-[${width}px] max-w-[${width}px] border-[1px] border-[solid] border-[silver] rounded-[4px] object-fill`
    : 'min-h-[50px] max-h-[50px] min-w-[50px] max-w-[50px] border-[2px] border-[solid] border-[silver] rounded-[8px] object-fill'

  return (
    <>
      {mode == 'list' ? (
        <span className={listSpanWidth}>
          {imgUrl ? (
            <img alt="Template Thumbnail" className={listImgWidth} src={imgUrl} />
          ) : (
            <Skeleton.Avatar size={50} />
          )}
        </span>
      ) : (
        <div style={{ display: 'block', minWidth: '350px !important', maxWidth: '350px !important' }}>
          <div
            className="flex flex-row justify-center"
            style={{
              minWidth: `${containerWidth}px`,
              maxWidth: `${containerWidth}px`,
              minHeight: `${containerHeight}px`,
              maxHeight: `${containerHeight}px`,
              paddingTop: padding,
              paddingLeft: padding,
              paddingRight: padding,
              paddingBottom: isOverflowing ? 0 : padding,
            }}
          >
            {imgUrl ? (
              <div
                className="overflow-hidden flex"
                style={{
                  height: isOverflowing ? `${(containerHeight || 0) - parseInt(padding || '')}px` : 'auto',
                  display: 'flex',
                  // eslint-disable-next-line no-nested-ternary
                  alignItems: mode === 'row' ? '' : isOverflowing ? 'flex-start' : 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  ref={imgRef}
                  src={imgUrl}
                  loading="lazy"
                  alt={templateId.toString()}
                  className="block"
                  style={{
                    width: `${imageWidth}px`,
                    maxWidth: `${imageWidth}px`,
                    maxHeight: isOverflowing ? 'none' : `${imageHeight}px`,
                    height: 'auto',
                    // eslint-disable-next-line no-nested-ternary
                    objectFit: mode === 'row' ? 'fill' : isOverflowing ? 'contain' : 'cover',
                    objectPosition: isOverflowing ? 'top' : 'center',
                    transformOrigin: 'top center',
                    border: '1px solid #E5E4E2',
                  }}
                  width={imageWidth}
                  height={imageHeight}
                  onLoad={(e) => {
                    const imgElement = e.target as HTMLImageElement
                    checkOverflow(imgElement)
                  }}
                />
              </div>
            ) : (
              <Skeleton.Avatar size={50} />
            )}
          </div>
        </div>
      )}
    </>
  )
}
